<template>
  <div>
    <section class="sign-in-page">
      <div class="container">
        <div class="
            row
            justify-content-center
            align-items-center
            height-self-center
          ">
          <div class="col-lg-5 col-md-12 align-self-center">
            <div class="sign-user_card">
              <div class="sign-in-page-data">
                <div class="sign-in-from w-100 m-auto">
                  <div
                    class="channel-logo"
                    data-animation-in="fadeInLeft"
                    data-delay-in="0.5"
                  >
                    <img
                      src="images/logo-full.png"
                      class="c-logon img-fluid"
                      alt="The DJs Vault"
                    />
                  </div>

                  <div
                    style="width: 100%; background-color: red; padding: 8px"
                    class="text-warning"
                    v-if="loginError"
                  >
                    {{ loginError }}
                  </div>

                  <div v-if="!this.reset">
                    <b-form-input
                      v-model="email"
                      placeholder="Enter your email"
                    ></b-form-input>
                    <b-form-input
                      v-model="password"
                      type="password"
                      placeholder="Enter your password"
                    ></b-form-input>

                    <button
                      class=" btn btn-danger"
                      @click="login()"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>

              <!-- <div class="text-warning" v-if="!this.reset">
                Attention existing DJ's Vault Members! This site requires a new
                password. Please click the "Forgot Password" link below.
              </div> -->

              <div
                class=" text-center mt-2"
                v-if="!this.reset"
              >
                By logging into this site you agree to the <u><a
                    href="https://www.iubenda.com/privacy-policy/93415460/legal"
                    target="_blank"
                  >Privacy Policy</a></u>, <u><a
                    href="https://www.iubenda.com/privacy-policy/93415460/cookie-policy"
                    target="_blank"
                  >Cookie Policy</a></u> and <u><a
                    href="https://www.iubenda.com/terms-and-conditions/93415460"
                    target="_blank"
                  >Terms and Condtions</a></u>
              </div>

              <div class="mt-3">
                <div
                  class="d-flex justify-content-center links mt-4"
                  v-if="!this.reset"
                >
                  <a
                    class="f-link text-center"
                    @click="forgotPW()"
                    style="cursor: pointer"
                  >
                    <span
                      class="text-danger"
                      style="cursor: pointer; text-decoration: underline"
                    >Forgot your password?</span></a>
                </div>
                <div
                  v-if="reset"
                  class="mt-4 text-center p-3"
                  style="border: 2px solid black"
                >
                  Please enter your email address below. <br /><span class="text-danger">You must already be a subscriber of The DJ's Vault</span>
                  <div class="row mt-4">
                    <div class="col-md-10">
                      <b-form-input
                        v-model="resetEmail"
                        placeholder="Enter your email address"
                      ></b-form-input>
                    </div>
                    <div class="col-md-2">
                      <button
                        @click="resetPassword()"
                        class="btn float-right"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                  <div
                    style="background-color: lime; color: black"
                    v-if="successMessage"
                  >
                    We have sent a link to {{ resetEmail }} to reset your
                    password.
                  </div>
                  <div
                    style="background-color: yellow; color: black"
                    v-if="failureMessage"
                  >
                    We were not able to match that email address to a registered
                    user.
                  </div>
                </div>

                <div
                  v-if="this.reset"
                  class="d-flex justify-content-center links mt-4 pointer"
                  @click="reset = false"
                >
                  <i class="fa fa-angle-double-left mr-2"></i> Back To Login
                </div>

                <div class="mt-4 text-center">
                  Have questions or need help? Contact us at <a
                    class="text-danger"
                    href="mailTo:help@thedjsvault.com"
                  >help@thedjsvault.com</a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
var firebaseui = require("firebaseui");

// Initialize the FirebaseUI Widget using Firebase.
var ui = new firebaseui.auth.AuthUI(firebase.auth());
import usersData from "/members.json";

export default {
  data () {
    return {
      reset: false,
      resetEmail: "",
      successMessage: false,
      failureMessage: false,
      email: "",
      password: "",
      originalUsers: [],
      loginError: null,
    };
  },
  mounted () {
    if (window.location.href.includes("thedjsvault-307019")) {
      window.location.replace("https://app.thedjsvault.com");
    }
    let _this = this;

    window.onerror = function (message, source, lineno, colno, error) {
      _this.loginError = "WINDOW ERROR: " + message + " - " + error;
      console.log("WINDOW ERROR: ", message + " - " + error);
    };

    this.originalUsers = usersData;
  },
  methods: {
    login () {
      this.loginError = null;

      const _this = this;
      try {
        firebase
          .auth()
          .fetchSignInMethodsForEmail(this.email)
          .then((result) => {

            if (result.length) {
              //User exists in auth list

              firebase
                .auth()
                .signInWithEmailAndPassword(
                  this.email.toString().trim(),
                  this.password
                )
                .then((userCredential) => {
                  // *********** Signed in *************
                  //////////////check Kartra and make sure the logged in user is active
                  var formdata = new FormData();
                  formdata.append("email", this.email.toString().trim());
                  var requestOptions = {
                    method: "POST",
                    body: formdata,
                    redirect: "follow",
                  };
                  fetch(
                    "https://app.cratehackers.com/version-test/api/1.1/wf/kartragetlead",
                    requestOptions
                  )
                    .then((response) => response.text())
                    .then((result) => {
                      let res = JSON.parse(result);
                      console.log(
                        "RESULT",
                        res.response.lead["_api_c2_lead_details.memberships"]
                      );

                      //check kartra memberships and make sure there is one that is active for The DJ's Vault
                      let memberships = res.response.lead[
                        "_api_c2_lead_details.memberships"
                      ].filter((membership) => {
                        return (
                          (membership._api_c2_name == "The DJ's Vault" &&
                            membership._api_c2_level_name == "Standard" &&
                            membership._api_c2_active == 1) ||
                          (membership._api_c2_name == "The DJ's Vault" &&
                            membership._api_c2_level_name == "Annual" &&
                            membership._api_c2_active == 1) ||
                          (membership._api_c2_name == "The DJ's Vault" &&
                            membership._api_c2_level_name == "Lite" &&
                            membership._api_c2_active == 1)
                        );
                      });

                      console.log("DJV Memberships", memberships);

                      if (memberships.length) {
                        //if memberships gt 0 then they have an active account
                        this.$router.push("/");
                      } else {
                        alert("It looks like you don't have an active account. If you believe this is an error please email help@thedjsvault.com")
                        this.$router.push("/login");
                      }
                    });
                  ///////////////End Kartra check
                })
                .catch((error) => {
                  //BAD PASSWORD
                  var errorCode = error.code;
                  var errorMessage = error.message;
                  this.loginError = errorMessage + " " + this.email;
                  console.log("Error2: ", error);
                });
            } else {
              //BAD EMAIL or User Doesn't exist
              this.loginError = "User Doesn't Exist" + " " + this.email;
              console.log("Error3: ", "User Doesn't Exist");
            }
          });
      } catch (err) {
        this.loginError = "Error: " + err + " " + this.email;
        console.log("Error4: ", err);
      }
    },

    forgotPW () {
      this.reset = true;
    },

    resetPassword () {
      this.failureMessage = false;
      this.successMessage = false;
      const _this = this;

      var actionCodeSettings = {
        // After password reset, the user will be give the ability to go back
        // to this page.
        url: "https://app.thedjsvault.com",
        // handleCodeInApp: false
      };

      firebase
        .auth()
        .sendPasswordResetEmail(this.resetEmail, actionCodeSettings)
        .then(() => {
          //user exists
          _this.successMessage = true;
          // ..
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;

          //we need to check the user list that didn't import
          const originalUser = this.originalUsers.find(
            (element) => element.Email == this.resetEmail
          );
          if (originalUser) {
            this.addUser(originalUser);
          } else {
            console.log("NO");
            _this.failureMessage = true;
          }
          /////////////
        });
    },
    addUser (user) {
      const request = require("request");
      const _this = this;
      var options = {
        method: "POST",
        url:
          "https://us-central1-thedjsvault-307019.cloudfunctions.net/app/api/kartra",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lead: {
            first_name: user.FirstName,

            last_name: user.LastName,

            email: user.Email,

            phone: "",
          },
          action: "membership_granted",
          action_details: {
            membership: {
              level_name: user.Membership,
            },
          },
        }),
      };
      request(options, function (error, response) {
        if (error) {
          console.error("ERROR:" + user.Email, error);
        }
        console.log(response);
        //console.log(response.body);
      });
    },
  },
};
</script>

<style></style>
